var exports = {};
var module = {
  exports: exports
};
// Generated by CoffeeScript 1.7.1
(function () {
  var exports,
      iferr,
      printerr,
      throwerr,
      tiferr,
      __slice = [].slice;

  iferr = function (fail, succ) {
    return function () {
      var a, err;
      err = arguments[0], a = 2 <= arguments.length ? __slice.call(arguments, 1) : [];

      if (err != null) {
        return fail(err);
      } else {
        return typeof succ === "function" ? succ.apply(null, a) : void 0;
      }
    };
  };

  tiferr = function (fail, succ) {
    return iferr(fail, function () {
      var a, err;
      a = 1 <= arguments.length ? __slice.call(arguments, 0) : [];

      try {
        return succ.apply(null, a);
      } catch (_error) {
        err = _error;
        return fail(err);
      }
    });
  };

  throwerr = iferr.bind(null, function (err) {
    throw err;
  });
  printerr = iferr(function (err) {
    return console.error(err.stack || err);
  });
  module.exports = exports = iferr;
  exports.iferr = iferr;
  exports.tiferr = tiferr;
  exports.throwerr = throwerr;
  exports.printerr = printerr;
}).call(exports);
export default module.exports;
export const iferr = module.exports.iferr,
      tiferr = module.exports.tiferr,
      throwerr = module.exports.throwerr,
      printerr = module.exports.printerr;